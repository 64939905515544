export const processPhasesByProcessIDCustom = /* GraphQL */ `
  query ProcessPhasesByProcessID(
    $processID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessPhasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processPhasesByProcessID(
      processID: $processID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        processID
        organizationID
        order
        isDeleted
        deletedAt
        ProcessSteps(filter: { isDeleted: { ne: true } }) {
          items {
            id
            name
            CustomProperties {
              name
            }
            description
            phaseID
            order
            type
            isRequired
            isHidden
            allowFailedStep
            hasNotApplicableOption
            customPassLabel
            customFailLabel
            customNALabel
            customPropertyId
            defaultValueOfCustomProperty
            propertyDropdownId
            mask
            defaultOption
            isDefaultToCurrentDate
            decimalPlace
            lowerLimit
            upperLimit
            isComputedLimits
            lowerLimitFormula
            upperLimitFormula
            allowValuesOutsideOfRange
            tagName
            organizationID
            isDeleted
            deletedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customPropertiesByOrganizationIDCustom = /* GraphQL */ `
  query CustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        deletedAt
        isDefault
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          items {
            options
            name
            id
          }
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const itemTypesByOrganizationIDCustom = /* GraphQL */ `
  query ItemTypesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        quantityProduced
        quantityRequired
        type
        projectId
        productSegment
        isActive
        isDeleted
        organizationID
        ItemTypeCustomProperties {
          items {
            customPropertyValue
            CustomProperties {
              name
              isRequired
              type
            }
          }
        }
        Items {
          items {
            id
          }
        }
        modifiedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const itemsByOrganizationIDCustom = /* GraphQL */ `
  query ItemsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        itemTypeId

        processId
        Processes {
          id
          name
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          modifiedBy
          createdAt
          updatedAt
          __typename
          ProcessPhases {
            items {
              ProcessSteps {
                items {
                  name
                  type
                  User {
                    firstName
                    lastName
                  }
                  updatedAt
                }
              }
              id
              order
              isDeleted
              name
            }
          }
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        longitude
        isDeleted
        organizationID
        ModifiedByUser {
          firstName
          lastName
        }
        modifiedBy
        ModifiedByUser {
          firstName
          lastName
        }
        CreatedByUser {
          firstName
          lastName
        }
        ItemsCustomProperties {
          items {
            customPropertyValue
            CustomProperties {
              name
              isRequired
              type
            }
          }
        }
        deletedAt
        ItemAttachments {
          items {
            User {
              firstName
              lastName
            }
            fileName
            name
            id
            updatedAt
            modifiedBy
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRoleBaseModuleAccessesCustom = /* GraphQL */ `
  query ListRoleBaseModuleAccesses(
    $filter: ModelRoleBaseModuleAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleBaseModuleAccesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        moduleId
        deniedFields
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          modulesRoleBaseModuleAccessId
          __typename
        }
        access
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByOrganizationID = /* GraphQL */ `
  query ItemTypeInShipmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          __typename
        }
        Shipments {
          id
          organizationID
          name
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByShipmentIdCustom = /* GraphQL */ `
  query ItemTypeInShipmentsByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          name
        }
        Shipments {
          id
          organizationID
          name
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          projectId
          quantityRequired
          quantityProduced
          Projects {
            name
          }
          ItemTypeCustomProperties {
            items {
              customPropertyValue
              CustomProperties {
                id
                name
                totalInShipment
              }
            }
          }
          ItemTypeInShipment(filter: { isDeleted: { ne: true } }) {
            items {
              quantity
              shipmentId
              id
            }
          }
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const userRoleByUserIdCustom = /* GraphQL */ `
  query UserRoleByUserId(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRoleByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        role
      }
    }
  }
`;

export const listOrganizationsCustom = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label
        description
        isEnabled
        enableShipment
        userLimit
        ssrsFolderName
        appTimeZone
        subscription
        createdAt
        updatedAt
      }
    }
  }
`;
export const searchProcessesCustom = /* GraphQL */ `
  query SearchProcesses(
    $filter: SearchableProcessesFilterInput
    $sort: [SearchableProcessesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProcessesAggregationInput]
  ) {
    searchProcesses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        isActive
        isDeleted
      }
      nextToken
      __typename
    }
  }
`;

export const itemProcessesByItemsIdCustom = /* GraphQL */ `
  query ItemProcessesByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        itemsId
        id
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Processes {
          name
          modifiedBy
          ProcessPhases {
            items {
              name
              order
              processID
              ProcessSteps(filter: { not: { isDeleted: { eq: false } } }) {
                items {
                  CustomProperties {
                    name
                  }
                  User {
                    firstName
                    lastName
                  }
                  allowFailedStep
                  allowValuesOutsideOfRange
                  createdAt
                  customFailLabel
                  type
                  customNALabel
                  customPropertyId
                  customPassLabel
                  decimalPlace
                  defaultOption
                  defaultValueOfCustomProperty
                  deletedAt
                  description
                  hasNotApplicableOption
                  id
                  isComputedLimits
                  isDefaultToCurrentDate
                  isDeleted
                  isHidden
                  isRequired
                  lowerLimit
                  lowerLimitFormula
                  mask
                  modifiedBy
                  name
                  order
                  organizationID
                  phaseID
                  tagName
                  propertyDropdownId
                  updatedAt
                  upperLimit
                  upperLimitFormula
                }
              }
            }
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const searchItemsCustom = `
  query SearchItems($filter:SearchableItemsFilterInput, $nextToken:String) {
    searchItems(filter: $filter, nextToken: $nextToken) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        latitude
        longitude
        shipmentId
        Shipments {
          id
          name
          notes
          number
          projectId
          isShipped
          shippedDate
          startWith
          maximumWeight
          modifiedBy
          defaultProcessId
          deletedAt
          createdAt
        }
          ItemAttachments {
          items {
            id
            deletedAt
            itemsId
            isDeleted
            name
            organizationID
            type
            createdAt
            createdBy
            updatedAt
            fileName
            modifiedBy
          }
        }
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          productSegment
          Projects {
            id
            inStock
            isCompleted
            isDeleted
              number
          name
            isItemLimited
            deletedAt
            defaultProcessId
            customerId
            completedAt
            createdAt
            Customers {
              id
              name
              organizationID
              createdAt
              deletedAt
            }
            Processes {
              id
              name
            }
          }
          __typename
        }
        ForneyVaultLogs {
          items {
            apiEndPoint
            createdAt
            httpMethodType
            isSent
            id
            itemsId
            modifiedBy
            organizationID
            request
            response
            sentOn
            updatedAt
            status
          }
        }
        ForneyVaultTestResults {
          items {
            id
            isDeleted
            itemSerialNumber
            itemsId
            labId
            length
            labName
            maxLoad
            maxStress
            modifiedBy
            number
            organizationID
            technicianInitials
            technicianName
            fractureType
            createdAt
            diameter
            ageToBreak
            ageAtBreak
            actualBreakDayAndTime
          }
        }
        ItemsCustomProperties (filter: {isDeleted: {ne: true}}) {
          items {
            createdAt
            customPropertyId
            customPropertyValue
            deletedAt
            id
            isDeleted
            itemsId
            organizationID
            propertyDropdownId
            requiredProperty
            updatedAt
            CustomProperties {
              name
              isDefault
              organizationID
              id
              propertyOf
              searchName
              type
            }
          }
        }
        ItemAttachments {
          items {
            id
            deletedAt
            itemsId
            isDeleted
            name
            organizationID
            type
            createdAt
            createdBy
            updatedAt
            fileName
            modifiedBy
          }
        }
        processId
        currentPhaseId
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        longitude
        isDeleted
        createdAt
        updatedAt
        organizationID
        createdBy
        CreatedByUser {
          id
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
      }
      nextToken
      total
    }
  }
`;

export const searchItemTypesCustom = `
  query SearchItemTypes($filter:SearchableItemTypesFilterInput, $limit: Int, $from: Int, $sort : [SearchableItemTypesSortInput], $nextToken:String) {
    searchItemTypes(filter: $filter, limit: $limit, from : $from, sort: $sort, nextToken: $nextToken) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          __typename
        }
        type
        projectId
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        modifiedBy
          ItemTypeCustomProperties {
        items {
          id
          customPropertyValue
          CustomProperties {
            name
            type
            id
          }
        }
      }
        User {
          id
          firstName
          lastName
          __typename
        }
      }
      nextToken
      total
      __typename
    }
  }
`;

export const searchProjectsCustom = `
  query SearchProjects($filter:SearchableProjectsFilterInput, $limit: Int, $from: Int, $sort : [SearchableProjectsSortInput], $nextToken:String) {
    searchProjects(filter: $filter, limit: $limit, from : $from, sort: $sort, nextToken: $nextToken) {
      items {
        id
        number
        name
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        defaultProcessId
        ProjectCustomProperties  (filter: {isDeleted: {ne: true}}){
          items {
            CustomProperties {
              id
              name
              type
              totalInShipment
              showInApp
              searchName
              propertyOf
              organizationID
              modifiedBy
              isRequired
              isDeleted
              updatedAt
              useAsWeight
            }
            customPropertyValue
            customPropertyId
            id
            isDeleted
          }
        }
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          __typename
        }
        customerId
        Customers {
          id
          name
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          __typename
        }
      }
      nextToken
      total
      __typename
    }
  }
`;

export const searchShipmentsCustom = `
  query SearchShipments(
    $filter: SearchableShipmentsFilterInput
    $sort: [SearchableShipmentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableShipmentsAggregationInput]
  ) {
    searchShipments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const itemTypesByProjectIdCustoms = /* GraphQL */ `
  query ItemTypesByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        ItemTypeCustomProperties(filter: { isDeleted: { ne: true } }) {
          items {
            customPropertyValue
            CustomProperties {
              name
              type
              isRequired
            }
          }
        }
        ItemTypeInShipment(filter: { isDeleted: { ne: true } }) {
          items {
            id
            quantity
            Shipments {
              createdAt
              defaultProcessId
              deletedAt
              id
              isAvailable
              isDeleted
              isShipped
              maximumWeight
              modifiedBy
              name
              notes
              number
              projectId
              scheduleDate
              searchNumber
              shippedDate
              startWith
              updatedAt
            }
          }
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          processesCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          __typename
        }
        Items {
          items {
            id
          }
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        modifiedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const itemsByItemTypeIdCustom = /* GraphQL */ `
  query ItemsByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        itemTypeId

        processId
        Processes {
          id
          name
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          modifiedBy
          createdAt
          updatedAt
          __typename
          ProcessPhases {
            items {
              ProcessSteps {
                items {
                  name
                  type
                  User {
                    firstName
                    lastName
                  }
                  updatedAt
                }
              }
              id
              order
              isDeleted
              name
            }
          }
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        longitude
        isDeleted
        organizationID
        ModifiedByUser {
          firstName
          lastName
        }
        modifiedBy
        ModifiedByUser {
          firstName
          lastName
        }
        CreatedByUser {
          firstName
          lastName
        }
        ItemsCustomProperties {
          items {
            customPropertyValue
            CustomProperties {
              name
              isRequired
              type
            }
          }
        }
        deletedAt
        ItemAttachments {
          items {
            User {
              firstName
              lastName
            }
            fileName
            name
            id
            updatedAt
            modifiedBy
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const documentsByOrganizationIDCustom = /* GraphQL */ `
  query DocumentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          items {
            ItemTypes {
              id
              name
              number
            }
            Items {
              id
              serialNumber
            }
            Projects {
              id
              name
              number
            }
            associatedAt
            createdAt
            documentId
            id
            itemTypeId
            itemsId
            projectId
            type
          }
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const searchCustomPropertiesCustom = /* GraphQL */ `
  query SearchCustomProperties(
    $filter: SearchableCustomPropertiesFilterInput
    $sort: [SearchableCustomPropertiesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomPropertiesAggregationInput]
  ) {
    searchCustomProperties(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const searchDocumentsCustom = /* GraphQL */ `
  query SearchDocuments(
    $filter: SearchableDocumentsFilterInput
    $sort: [SearchableDocumentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDocumentsAggregationInput]
  ) {
    searchDocuments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          items {
            ItemTypes {
              id
              name
              number
            }
            Items {
              id
              serialNumber
            }
            Projects {
              id
              name
              number
            }
            associatedAt
            createdAt
            documentId
            id
            itemTypeId
            itemsId
            projectId
            type
          }
          nextToken
          __typename
        }
        isFromMobile
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const searchDocumentsV1 = /* GraphQL */ `
  query SearchDocuments(
    $filter: SearchableDocumentsFilterInput
    $sort: [SearchableDocumentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDocumentsAggregationInput]
  ) {
    searchDocuments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          items {
            id
            itemTypeId
            itemsId
            organizationID
            type
          }
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const documentAttachmentsbyItemsidCustom = /* GraphQL */ `
  query documentAttachmentsByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        documentId
        id
        type
        version
        updatedAt
        itemsId
        associatedAt
        document {
          fileName
          fileType
          id
          name
          organizationID
          extension
        }
        modifiedByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
