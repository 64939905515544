import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Drawer, Button, Typography, Row, Col } from "antd";
import { DoubleRightOutlined, EditOutlined } from "@ant-design/icons";
import SelectInput from "Common/Components/SelectInput/SelectInput";
import { Formik, Form, Field } from "formik";
import AssociateForm from "../AssociateForm";
import { createAttachmentToDocument } from "../commonFunction";
import { CommonError } from "utils/Helper/CommonError";
import {
  createDocumentAttachments,
  deleteDocumentAttachments,
  updateDocumentAttachments,
} from "utils/Actions";
import { associateApi } from "utils/RESTApi";
import { useSelector } from "react-redux";
import { getCurrentUser } from "store/slices/loginSlice";
import { setFullScreenWidth } from "utils/Helper/commonMethods";
export default function BulkEdit(props) {
  const {
    open,
    screens,
    setOpen,
    selectedDocuments,
    projectData,
    itemTypeData,
    organizationID,
    editDocument = false,
    editToAttachment,
    currentTokenId,
    handleCustomBulkEdit = () => {},
    actionType,
  } = props;

  const [drawerWidth, setDrawerWidth] = useState(706);
  const [isFullScreen, setFullScreen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [formRefs, setFormRefs] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(0);

  const currentUser = useSelector(getCurrentUser);

  const [idArrays, setIdArrays] = useState({
    documentId: "",
    attachments: [
      {
        projectId: [],
        itemTypeId: [],
        itemsId: [],
        attachmentId: [],
      },
    ],
  });

  const processData = () => {
    const groupedData = editToAttachment.reduce((acc, item) => {
      const {
        documentId,
        id: attachmentId,
        projectId,
        itemTypeId,
        itemsId,
      } = item;

      const existingDoc = acc.find((doc) => doc.documentId === documentId);

      if (existingDoc) {
        if (projectId) existingDoc.attachments[0].projectId.push(projectId);
        if (itemTypeId) existingDoc.attachments[0].itemTypeId.push(itemTypeId);
        if (itemsId) existingDoc.attachments[0].itemsId.push(itemsId);
        existingDoc.attachments[0].attachmentId.push(attachmentId);
      } else {
        acc.push({
          documentId,
          attachments: [
            {
              projectId: projectId ? [projectId] : [],
              itemTypeId: itemTypeId ? [itemTypeId] : [],
              itemsId: itemsId ? [itemsId] : [],
              attachmentId: [attachmentId],
            },
          ],
        });
      }

      return acc;
    }, []);

    setIdArrays(groupedData);
  };

  const filterByDocumentId = (data) => {
    const result = [];
    const documentIdSet = new Set(); // To track unique documentIds

    const traverseAndFilter = (array) => {
      array.forEach((item) => {
        if (Array.isArray(item)) {
          traverseAndFilter(item);
        } else if (
          item.documentId &&
          item.documentId !== "" &&
          !documentIdSet.has(item.documentId)
        ) {
          documentIdSet.add(item.documentId); // Add to the set to avoid duplicates
          result.push(item); // Add the object to the result array
        }
      });
    };

    traverseAndFilter(data);
    return setIdArrays(result);
  };

  useLayoutEffect(() => {
    if (open && editToAttachment) {
      setEditData(editToAttachment);

      processData();
    }

    if (!editToAttachment) {
      selectedDocuments?.map((item) => {
        const data = [
          idArrays,
          {
            documentId: item?.id,
            attachments: [
              {
                projectId: [],
                itemTypeId: [],
                itemsId: [],
                attachmentId: [],
              },
            ],
          },
        ];

        filterByDocumentId(data);
      });
    }
  }, [open, editToAttachment]);

  useEffect(() => {
    if (open && actionType) {
      setActiveButton(actionType);
      setCurrentScreen(
        actionType === 0
          ? {
              name: "item",
              label: "Item",
              value: 0,
            }
          : {
              name: "itemTypes",
              label: "Item Types",
              value: 1,
            }
      );
    }
  }, [actionType]);

  const [currentScreen, setCurrentScreen] = useState({
    name: "item",
    label: "Item",
  });

  const ScreenButtons = ({ currentScreen, setCurrentScreen }) => {
    const buttons = [
      { name: "item", label: "Item", value: 0 },
      { name: "itemTypes", label: "Item Types", value: 1 },
    ];

    return (
      <Row className="gap-12">
        {buttons.map((button, key) => (
          <Button
            key={key}
            type="primary"
            shape="round"
            className={`drawer-btn-child ${
              currentScreen?.name === button?.name ? "" : "gray-btn"
            }`}
            htmlType="submit"
            onClick={() => {
              setCurrentScreen(button);
              setActiveButton(button?.value);
            }}
          >
            {button.label}
          </Button>
        ))}
      </Row>
    );
  };

  const createAttachment = useCallback(
    (value, ref, documentId) => {
      setLoading(true);
      let input;
      const attachments = idArrays?.filter(
        (item) => item?.documentId === value?.documentId
      );

      if (editDocument) {
        input = createAttachmentToDocument(
          value?.itemType,
          value?.items,
          value?.project,
          value?.documentId,
          editData?.length > 0 && attachments?.length > 0
            ? attachments[0]?.attachments[0]?.attachmentId
            : null,
          editDocument,
          organizationID,
          activeButton,
          editData
        );
      } else {
        input = createAttachmentToDocument(
          value?.itemType,
          value?.items,
          value?.project,
          value?.documentId,
          null,
          editDocument,
          organizationID,
          activeButton,
          editData
        );
      }

      try {
        let params;
        input?.forEach(async (input) => {
          if (!editDocument || editData.length === 0 || input?.id === null) {
            if (editData && input?.id === null) {
              delete input?.id;
            }

            params = {
              createDocumentAttachments: [
                { ...input, modifiedBy: currentUser?.sub },
              ],
            };
          } else {
            params = {
              updateDocumentAttachments: [
                { ...input, modifiedBy: currentUser?.sub },
              ],
            };
          }

          if (
            (input?.type === 0 && input?.itemsId !== null) ||
            (input?.type === 1 && input?.itemTypeId !== null)
          ) {
            await associateApi(params);
          }

          if (
            (input?.type === 0 && input?.itemsId === null) ||
            (input?.type === 1 && input?.itemTypeId === null)
          ) {
            await deleteDocumentAttachments({ id: input?.id }, currentTokenId);
          }
        });
        setLoading(false);
        setOpen(false);
      } catch (error) {
        setLoading(false);
        CommonError(error);
      }
    },
    [
      editDocument,
      editData,
      idArrays,
      organizationID,
      activeButton,
      currentTokenId,
      setOpen,
    ]
  );

  const handleCreateAttachments = useCallback(async () => {
    setLoading(true);

    try {
      // const promises

      let hasNonEmptyForm = false;

      const allFormsChecked = Object.values(formRefs).every(
        (formRef, index) => {
          if (formRef && formRef.current) {
            formRef.current.submitForm();
            const errors = formRef.current.errors;
            if (Object.keys(errors).length !== 0) {
              hasNonEmptyForm = true;
            }
            return true;
          }
          return true;
        }
      );

      if (hasNonEmptyForm) {
      } else {
        Object.values(formRefs).map(async (formRef) => {
          if (formRef && formRef.current) {
            await formRef.current.submitForm();
            const values = formRef.current.values;
            const documentId = formRef.documentId;
            if (Object.keys(formRef.current.errors).length === 0) {
              return createAttachment(values, null, documentId);
            }
          }
        });
      }

      await Promise.all(
        Object.values(formRefs).map((formRef, index) =>
          formRef?.current?.submitForm()
        )
      );
      setLoading(false);
      handleCustomBulkEdit("Ref data");
    } catch (error) {
      setLoading(false);
      CommonError(error);
    }
  }, [formRefs, createAttachment]);

  return (
    <>
      <Drawer
        className="related-item-drawer brawer-with-btn custom-prop"
        width={drawerWidth}
        title={
          <>
            <Row
              align="middle"
              justify="space-between"
              className="drawer-title"
            >
              <Row align="middle">
                <Button
                  type="text"
                  className="text-gray fs-14 p-0"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <DoubleRightOutlined />
                </Button>
                <Typography.Title className="mb-0 ml-10 fs-21">
                  Bulk Edit
                </Typography.Title>
              </Row>
              <Typography.Title
                level={5}
                className="mb-0 ml-10 text-gray fw-400 fs-14 cursor-pointer text-primary"
                onClick={() => {
                  setFullScreenWidth(
                    !isFullScreen,
                    setDrawerWidth,
                    setFullScreen
                  );
                }}
              >
                {isFullScreen ? "View in small screen" : "  View in fullscreen"}
              </Typography.Title>
            </Row>
          </>
        }
        closeIcon={null}
        closable={false}
        placement={screens.xs ? "bottom" : "right"}
        onClose={() => {
          setOpen(true);
        }}
        open={open}
      >
        <Row className="mx-20">
          <ScreenButtons
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
          />
        </Row>

        {selectedDocuments?.map((item, index) => {
          return (
            <div className="mt-20">
              <Row justify="space-between">
                <Typography.Title level={3}>{item?.name}</Typography.Title>
                <Button type="text" className="blueBtn">
                  <EditOutlined /> Edit
                </Button>
              </Row>

              <AssociateForm
                actionType={activeButton === 0 ? "Item" : "Item Types"}
                projectData={projectData}
                itemTypeData={itemTypeData}
                organizationID={organizationID}
                formName={`bulkEditForm_${item.id}`}
                handleSubmitForm={(values, ref) => {
                  // This function will be called by Formik's onSubmit
                }}
                bulkEdit={true}
                documentId={item.id}
                setFormRef={(ref) => {
                  setFormRefs((prev) => ({ ...prev, [item.id]: ref }));
                }}
                dataToEdit={
                  editToAttachment &&
                  editToAttachment?.filter(
                    (document) => document?.documentId === item?.id
                  )
                }
              />
            </div>
          );
        })}

        <Button
          type="primary"
          onClick={handleCreateAttachments}
          loading={loading}
          className="mt-20"
        >
          Save
        </Button>
      </Drawer>
    </>
  );
}
