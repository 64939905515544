import React, { lazy } from "react";
import { Route, Routes, Navigate } from "react-router";
import { createBrowserHistory } from "history";

// import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import MainLayoutRoute from "./MainLayoutRoute";
import PublicLayoutRoute from "./PublicLayoutRoute";
import NcdotHicams from "Pages/Integration/NcdotHicams/NcdotHicams";
import ForneyVault from "Pages/Integration/ForneyVault/ForneyVault";
import NotFoundPage from "Common/Components/NotFoundPage/NotFoundPage";
import Documents from "Pages/Documents/Documents";

const Login = lazy(() => import("../authUser/Login/Login"));
const ForgotPassword = lazy(() =>
  import("../authUser/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("authUser/ForgotPassword/ResetPassword")
);
const AdminLogin = lazy(() => import("authUser/Login/AdminLogin"));
const Settings = lazy(() => import("authUser/Login/Settings"));
const SubscriptionManagement = lazy(() =>
  import("authUser/Login/SubscriptionManagement")
);
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const Items = lazy(() => import("../Pages/Items/Items"));
const ItemsGroups = lazy(() => import("../Pages/ItemGroups/ItemGroups"));
const Projects = lazy(() => import("../Pages/Projects/Projects"));
const Shipments = lazy(() => import("../Pages/Shipments/Shipments"));
const Users = lazy(() => import("../Pages/Setup/Users/Users"));
const CustomProperties = lazy(() =>
  import("../Pages/Setup/CustomProperties/CustomProperties")
);
const Processes = lazy(() => import("../Pages/Setup/Processes/Processes"));
const ItemTypes = lazy(() => import("../Pages/Setup/ItemTypes/ItemsTypes"));
const Customer = lazy(() => import("../Pages/Setup/Customer/Customer.js"));
const Report = lazy(() => import("../Pages/Reports/Reports"));
const RFID = lazy(() => import("../Pages/Rfid/Rfid"));
const BoldReport = lazy(() => import("../Pages/Reports/BoldReports"));
const ReportViewer = lazy(() => import("../Pages/Reports/ReportDrawer"));

const routes = [
  {
    component: <Login />,
    path: "/login",
  },
  {
    component: <AdminLogin />,
    path: "/adminlog",
  },
  {
    component: <Settings />,
    path: "/settings",
  },
  {
    component: <SubscriptionManagement />,
    path: "/subscription-management",
  },
  {
    component: <ForgotPassword />,
    path: "/forgot-password",
  },
  {
    component: <ResetPassword />,
    path: "/reset-password",
  },
];

const privateRoutes = [
  {
    component: <Dashboard />,
    path: "/Dashboard",
  },
  {
    component: <Items />,
    path: "/Items",
  },
  {
    component: <ItemsGroups />,
    path: "/Items-Groups",
  },
  {
    component: <Projects />,
    path: "/projects",
  },
  {
    component: <Shipments />,
    path: "/shipments",
  },
  {
    component: <Documents />,
    path: "/documents",
  },
  {
    component: <CustomProperties />,
    path: "/Custom-properties",
  },
  {
    component: <Processes />,
    path: "/processes",
  },
  {
    component: <Users />,
    path: "/users",
  },
  // {
  //   component: <Notifications />,
  //   path: "/notifications",
  // },
  // {
  //   component: <Notifications />,
  //   path: "/notifications",
  // },
  {
    component: <RFID />,
    path: "/rfid",
  },
  {
    component: <Report />,
    path: "/reports",
  },
  {
    component: <BoldReport />,
    path: "/bold-reports",
  },
  {
    component: <ReportViewer />,
    path: "/view/report",
  },
  {
    component: <ItemTypes />,
    path: "/item-type",
  },
  {
    component: <NcdotHicams />,
    path: "/ncdot-hicams",
  },
  {
    component: <Customer />,
    path: "/customers",
  },
  {
    component: <ForneyVault />,
    path: "/forney-vault",
  },
];

export default function Routesapp() {
  const history = createBrowserHistory();

  return (
    <BrowserRouter basename="/" history={history}>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayoutRoute />}>
          {routes.map((route, key) => (
            <Route key={key} element={route.component} path={route.path} />
          ))}
        </Route>

        {/* Protected Routes */}
        <Route element={<MainLayoutRoute />}>
          {/* Specify the protected route's children */}
          {privateRoutes.map((route, key) => (
            <Route key={key} element={route.component} path={route.path} />
          ))}
          <Route path="/" element={<Navigate to="/Items" />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
