/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOpenSearchErrors = /* GraphQL */ `
  query GetOpenSearchErrors($id: ID!) {
    getOpenSearchErrors(id: $id) {
      id
      data
      error
      eventName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOpenSearchErrors = /* GraphQL */ `
  query ListOpenSearchErrors(
    $filter: ModelOpenSearchErrorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenSearchErrors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        error
        eventName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRedshiftOperationErrorLogs = /* GraphQL */ `
  query GetRedshiftOperationErrorLogs($id: ID!) {
    getRedshiftOperationErrorLogs(id: $id) {
      id
      error_message
      deadLockError
      query
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRedshiftOperationErrorLogs = /* GraphQL */ `
  query ListRedshiftOperationErrorLogs(
    $filter: ModelRedshiftOperationErrorLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedshiftOperationErrorLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        error_message
        deadLockError
        query
        details
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganizationCopyErrorLogs = /* GraphQL */ `
  query GetOrganizationCopyErrorLogs($id: ID!) {
    getOrganizationCopyErrorLogs(id: $id) {
      id
      error
      organizationId
      tableName
      type
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationCopyErrorLogs = /* GraphQL */ `
  query ListOrganizationCopyErrorLogs(
    $filter: ModelOrganizationCopyErrorLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationCopyErrorLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        error
        organizationId
        tableName
        type
        user
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      legacyUserId
      firstName
      lastName
      email
      Organization {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastLoggedIn
      isEnabled
      isDeleted
      modifiedBy
      deletedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          projectsForneyVaultSettingId
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bylegacyUserId = /* GraphQL */ `
  query BylegacyUserId(
    $legacyUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bylegacyUserId(
      legacyUserId: $legacyUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      label
      description
      Users {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isEnabled
      enableShipment
      userLimit
      appTimeZone
      token
      expiryTime
      ssrsFolderName
      subscription
      createdAt
      updatedAt
      CustomProperties {
        items {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Customers {
        items {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          projectsForneyVaultSettingId
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      RfidBucketList {
        items {
          id
          organizationID
          integrationId
          projectId
          name
          description
          enrollmentRule
          qualifications
          bucketItems
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganizationUserRole = /* GraphQL */ `
  query GetOrganizationUserRole($id: ID!) {
    getOrganizationUserRole(id: $id) {
      id
      userID
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationUserRoles = /* GraphQL */ `
  query ListOrganizationUserRoles(
    $filter: ModelOrganizationUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationUserRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userRoleByUserId = /* GraphQL */ `
  query UserRoleByUserId(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRoleByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationUserRolesByOrganizationIDAndUserID = /* GraphQL */ `
  query OrganizationUserRolesByOrganizationIDAndUserID(
    $organizationID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationUserRolesByOrganizationIDAndUserID(
      organizationID: $organizationID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomProperties = /* GraphQL */ `
  query GetCustomProperties($id: ID!) {
    getCustomProperties(id: $id) {
      id
      name
      searchName
      type
      propertyOf
      isRequired
      showInApp
      totalInShipment
      useAsWeight
      modifiedBy
      isDeleted
      isDefault
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdownHistory {
        items {
          id
          options
          createdAt
          updatedAt
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processed {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          projectsForneyVaultSettingId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listCustomProperties = /* GraphQL */ `
  query ListCustomProperties(
    $filter: ModelCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customPropertyByName = /* GraphQL */ `
  query CustomPropertyByName(
    $searchName: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customPropertyByName(
      searchName: $searchName
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customPropertiesByOrganizationID = /* GraphQL */ `
  query CustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomProperties = /* GraphQL */ `
  query SearchCustomProperties(
    $filter: SearchableCustomPropertiesFilterInput
    $sort: [SearchableCustomPropertiesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomPropertiesAggregationInput]
  ) {
    searchCustomProperties(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPropertyDropdown = /* GraphQL */ `
  query GetPropertyDropdown($id: ID!) {
    getPropertyDropdown(id: $id) {
      id
      name
      options
      isDeleted
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listPropertyDropdowns = /* GraphQL */ `
  query ListPropertyDropdowns(
    $filter: ModelPropertyDropdownFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyDropdowns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const propertyDropdownsByOrganizationID = /* GraphQL */ `
  query PropertyDropdownsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyDropdownFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyDropdownsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const propertyDropdownsByCustomPropertyId = /* GraphQL */ `
  query PropertyDropdownsByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyDropdownFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyDropdownsByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPropertyDropdownHistory = /* GraphQL */ `
  query GetPropertyDropdownHistory($id: ID!) {
    getPropertyDropdownHistory(id: $id) {
      id
      options
      createdAt
      updatedAt
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listPropertyDropdownHistories = /* GraphQL */ `
  query ListPropertyDropdownHistories(
    $filter: ModelPropertyDropdownHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyDropdownHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        options
        createdAt
        updatedAt
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const propertyDropdownHistoriesByCustomPropertyId = /* GraphQL */ `
  query PropertyDropdownHistoriesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyDropdownHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyDropdownHistoriesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        options
        createdAt
        updatedAt
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcesses = /* GraphQL */ `
  query GetProcesses($id: ID!) {
    getProcesses(id: $id) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processesCompletedProcessStepsId
      __typename
    }
  }
`;
export const listProcesses = /* GraphQL */ `
  query ListProcesses(
    $filter: ModelProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processByName = /* GraphQL */ `
  query ProcessByName(
    $searchName: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processByName(
      searchName: $searchName
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processesByOrganizationID = /* GraphQL */ `
  query ProcessesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processesByCustomPropertyId = /* GraphQL */ `
  query ProcessesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProcesses = /* GraphQL */ `
  query SearchProcesses(
    $filter: SearchableProcessesFilterInput
    $sort: [SearchableProcessesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProcessesAggregationInput]
  ) {
    searchProcesses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProcessSnapShot = /* GraphQL */ `
  query GetProcessSnapShot($id: ID!) {
    getProcessSnapShot(id: $id) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processSnapShotCompletedProcessStepsId
      __typename
    }
  }
`;
export const listProcessSnapShots = /* GraphQL */ `
  query ListProcessSnapShots(
    $filter: ModelProcessSnapShotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcessSnapShots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processSnapShotByName = /* GraphQL */ `
  query ProcessSnapShotByName(
    $searchName: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProcessSnapShotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processSnapShotByName(
      searchName: $searchName
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processSnapShotsByOrganizationID = /* GraphQL */ `
  query ProcessSnapShotsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessSnapShotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processSnapShotsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processSnapShotsByCustomPropertyId = /* GraphQL */ `
  query ProcessSnapShotsByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessSnapShotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processSnapShotsByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProcessSnapShots = /* GraphQL */ `
  query SearchProcessSnapShots(
    $filter: SearchableProcessSnapShotFilterInput
    $sort: [SearchableProcessSnapShotSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProcessSnapShotAggregationInput]
  ) {
    searchProcessSnapShots(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProcessPhases = /* GraphQL */ `
  query GetProcessPhases($id: ID!) {
    getProcessPhases(id: $id) {
      id
      name
      processID
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      order
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processPhasesCompletedProcessStepsId
      __typename
    }
  }
`;
export const listProcessPhases = /* GraphQL */ `
  query ListProcessPhases(
    $filter: ModelProcessPhasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcessPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processPhasesByProcessID = /* GraphQL */ `
  query ProcessPhasesByProcessID(
    $processID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessPhasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processPhasesByProcessID(
      processID: $processID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processPhasesByOrganizationID = /* GraphQL */ `
  query ProcessPhasesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessPhasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processPhasesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcessSteps = /* GraphQL */ `
  query GetProcessSteps($id: ID!) {
    getProcessSteps(id: $id) {
      id
      name
      description
      phaseID
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      order
      type
      isRequired
      allowFailedStep
      hasNotApplicableOption
      defaultOption
      customPassLabel
      customFailLabel
      customNALabel
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultValueOfCustomProperty
      isHidden
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      mask
      isDefaultToCurrentDate
      decimalPlace
      lowerLimit
      upperLimit
      isComputedLimits
      lowerLimitFormula
      upperLimitFormula
      allowValuesOutsideOfRange
      tagName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listProcessSteps = /* GraphQL */ `
  query ListProcessSteps(
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcessSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processStepsByPhaseID = /* GraphQL */ `
  query ProcessStepsByPhaseID(
    $phaseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processStepsByPhaseID(
      phaseID: $phaseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processStepsByCustomPropertyId = /* GraphQL */ `
  query ProcessStepsByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processStepsByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processStepsByPropertyDropdownId = /* GraphQL */ `
  query ProcessStepsByPropertyDropdownId(
    $propertyDropdownId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processStepsByPropertyDropdownId(
      propertyDropdownId: $propertyDropdownId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processStepsByOrganizationID = /* GraphQL */ `
  query ProcessStepsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processStepsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processStepsByModifiedBy = /* GraphQL */ `
  query ProcessStepsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processStepsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemTypes = /* GraphQL */ `
  query GetItemTypes($id: ID!) {
    getItemTypes(id: $id) {
      id
      number
      name
      searchName
      searchNumber
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      type
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      productSegment
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      quantityRequired
      quantityProduced
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          projectsForneyVaultSettingId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listItemTypes = /* GraphQL */ `
  query ListItemTypes(
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesByNumber = /* GraphQL */ `
  query ItemTypesByNumber(
    $number: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByNumber(
      number: $number
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesBySearchNumber = /* GraphQL */ `
  query ItemTypesBySearchNumber(
    $searchNumber: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesBySearchNumber(
      searchNumber: $searchNumber
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesByDefaultProcessId = /* GraphQL */ `
  query ItemTypesByDefaultProcessId(
    $defaultProcessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByDefaultProcessId(
      defaultProcessId: $defaultProcessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesByProjectId = /* GraphQL */ `
  query ItemTypesByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesByOrganizationID = /* GraphQL */ `
  query ItemTypesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypesByModifiedBy = /* GraphQL */ `
  query ItemTypesByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypesByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchItemTypes = /* GraphQL */ `
  query SearchItemTypes(
    $filter: SearchableItemTypesFilterInput
    $sort: [SearchableItemTypesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableItemTypesAggregationInput]
  ) {
    searchItemTypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getItemTypeCustomProperties = /* GraphQL */ `
  query GetItemTypeCustomProperties($id: ID!) {
    getItemTypeCustomProperties(id: $id) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listItemTypeCustomProperties = /* GraphQL */ `
  query ListItemTypeCustomProperties(
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemTypeCustomProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeCustomPropertiesByCustomPropertyId = /* GraphQL */ `
  query ItemTypeCustomPropertiesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeCustomPropertiesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeCustomPropertiesByItemTypeId = /* GraphQL */ `
  query ItemTypeCustomPropertiesByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeCustomPropertiesByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeCustomPropertiesByPropertyDropdownId = /* GraphQL */ `
  query ItemTypeCustomPropertiesByPropertyDropdownId(
    $propertyDropdownId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeCustomPropertiesByPropertyDropdownId(
      propertyDropdownId: $propertyDropdownId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeCustomPropertiesByOrganizationID = /* GraphQL */ `
  query ItemTypeCustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeCustomPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeCustomPropertiesByOrganizationUpdatedAt = /* GraphQL */ `
  query ItemTypeCustomPropertiesByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeCustomPropertiesByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems($id: ID!) {
    getItems(id: $id) {
      id
      serialNumber
      searchNumber
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      currentPhaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessComplete
      hasFailedSteps
      serialNumberType
      latitude
      sentToHicams
      longitude
      isDeleted
      createdAt
      updatedAt
      forneyTestPending
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByNumber = /* GraphQL */ `
  query ItemsByNumber(
    $serialNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByNumber(
      serialNumber: $serialNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsBySearchNumber = /* GraphQL */ `
  query ItemsBySearchNumber(
    $searchNumber: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsBySearchNumber(
      searchNumber: $searchNumber
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByItemTypeId = /* GraphQL */ `
  query ItemsByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByProcessId = /* GraphQL */ `
  query ItemsByProcessId(
    $processId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByProcessId(
      processId: $processId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByProjectId = /* GraphQL */ `
  query ItemsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByShipmentId = /* GraphQL */ `
  query ItemsByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByCurrentPhaseId = /* GraphQL */ `
  query ItemsByCurrentPhaseId(
    $currentPhaseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCurrentPhaseId(
      currentPhaseId: $currentPhaseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByOrganizationID = /* GraphQL */ `
  query ItemsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByOrganizationUpdatedAt = /* GraphQL */ `
  query ItemsByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByCreatedBy = /* GraphQL */ `
  query ItemsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByModifiedBy = /* GraphQL */ `
  query ItemsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems(
    $filter: SearchableItemsFilterInput
    $sort: [SearchableItemsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableItemsAggregationInput]
  ) {
    searchItems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getItemsCustomProperties = /* GraphQL */ `
  query GetItemsCustomProperties($id: ID!) {
    getItemsCustomProperties(id: $id) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listItemsCustomProperties = /* GraphQL */ `
  query ListItemsCustomProperties(
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemsCustomProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsCustomPropertiesByCustomPropertyId = /* GraphQL */ `
  query ItemsCustomPropertiesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsCustomPropertiesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsCustomPropertiesByItemsId = /* GraphQL */ `
  query ItemsCustomPropertiesByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsCustomPropertiesByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsCustomPropertiesByPropertyDropdownId = /* GraphQL */ `
  query ItemsCustomPropertiesByPropertyDropdownId(
    $propertyDropdownId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsCustomPropertiesByPropertyDropdownId(
      propertyDropdownId: $propertyDropdownId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsCustomPropertiesByOrganizationID = /* GraphQL */ `
  query ItemsCustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsCustomPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemCustomPropertiesByOrganizationUpdatedAt = /* GraphQL */ `
  query ItemCustomPropertiesByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemsCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemCustomPropertiesByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProjects = /* GraphQL */ `
  query GetProjects($id: ID!) {
    getProjects(id: $id) {
      id
      number
      name
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      isItemLimited
      isCompleted
      inStock
      completedAt
      isDeleted
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          projectsForneyVaultSettingId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByNumber = /* GraphQL */ `
  query ProjectsByNumber(
    $number: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByNumber(
      number: $number
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByDefaultProcessId = /* GraphQL */ `
  query ProjectsByDefaultProcessId(
    $defaultProcessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByDefaultProcessId(
      defaultProcessId: $defaultProcessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByCustomerId = /* GraphQL */ `
  query ProjectsByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByOrganizationID = /* GraphQL */ `
  query ProjectsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByModifiedBy = /* GraphQL */ `
  query ProjectsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectsFilterInput
    $sort: [SearchableProjectsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectsAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProjectCustomProperties = /* GraphQL */ `
  query GetProjectCustomProperties($id: ID!) {
    getProjectCustomProperties(id: $id) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listProjectCustomProperties = /* GraphQL */ `
  query ListProjectCustomProperties(
    $filter: ModelProjectCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectCustomProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectCustomPropertiesByCustomPropertyId = /* GraphQL */ `
  query ProjectCustomPropertiesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCustomPropertiesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectCustomPropertiesByProjectId = /* GraphQL */ `
  query ProjectCustomPropertiesByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCustomPropertiesByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectCustomPropertiesByPropertyDropdownId = /* GraphQL */ `
  query ProjectCustomPropertiesByPropertyDropdownId(
    $propertyDropdownId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCustomPropertiesByPropertyDropdownId(
      propertyDropdownId: $propertyDropdownId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectCustomPropertiesByOrganizationID = /* GraphQL */ `
  query ProjectCustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCustomPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTimeZones = /* GraphQL */ `
  query GetTimeZones($id: ID!) {
    getTimeZones(id: $id) {
      id
      iana
      standardName
      timeDifference
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listTimeZones = /* GraphQL */ `
  query ListTimeZones(
    $filter: ModelTimeZonesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iana
        standardName
        timeDifference
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($id: ID!) {
    getCustomers(id: $id) {
      id
      name
      searchName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      isActive
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByOrganizationID = /* GraphQL */ `
  query CustomersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomersFilterInput
    $sort: [SearchableCustomersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomersAggregationInput]
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getItemAttachments = /* GraphQL */ `
  query GetItemAttachments($id: ID!) {
    getItemAttachments(id: $id) {
      id
      name
      fileName
      type
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdBy
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      itemAttachmentsCompletedProcessStepsId
      __typename
    }
  }
`;
export const listItemAttachments = /* GraphQL */ `
  query ListItemAttachments(
    $filter: ModelItemAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemAttachmentsByItemsId = /* GraphQL */ `
  query ItemAttachmentsByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemAttachmentsByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemAttachmentsByOrganizationID = /* GraphQL */ `
  query ItemAttachmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemAttachmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemAttachmentsByModifiedBy = /* GraphQL */ `
  query ItemAttachmentsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemAttachmentsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompletedProcessSteps = /* GraphQL */ `
  query GetCompletedProcessSteps($id: ID!) {
    getCompletedProcessSteps(id: $id) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      phaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      itemProcessId
      ItemProcess {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      processStepId
      ProcessSteps {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      stepType
      isDeleted
      deletedAt
      finishedOnUtc
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      notes
      isFailed
      result
      value
      valueOld
      dateValue
      dateValueOld
      latitude
      latitudeOld
      longitude
      longitudeOld
      numericValue
      numericValueOld
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      itemTypeIdOld
      customItemTypeIdOld
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      createdBy
      modifiedBy
      finishedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      pictureId
      isActive
      Picture {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      isLegacy
      customPropertiesCompletedProcessStepsId
      itemProcessesCompletedProcessStepsId
      __typename
    }
  }
`;
export const listCompletedProcessSteps = /* GraphQL */ `
  query ListCompletedProcessSteps(
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompletedProcessSteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByItemsId = /* GraphQL */ `
  query CompletedProcessStepsByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByOrganizationID = /* GraphQL */ `
  query CompletedProcessStepsByOrganizationID(
    $organizationID: ID!
    $itemsId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByOrganizationID(
      organizationID: $organizationID
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByOrganizationUpdatedAt = /* GraphQL */ `
  query CompletedProcessStepsByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByProcessStepId = /* GraphQL */ `
  query CompletedProcessStepsByProcessStepId(
    $processStepId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByProcessStepId(
      processStepId: $processStepId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByProjectId = /* GraphQL */ `
  query CompletedProcessStepsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByItemTypeId = /* GraphQL */ `
  query CompletedProcessStepsByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByItemTypeIdOld = /* GraphQL */ `
  query CompletedProcessStepsByItemTypeIdOld(
    $itemTypeIdOld: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByItemTypeIdOld(
      itemTypeIdOld: $itemTypeIdOld
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByCustomItemTypeIdOld = /* GraphQL */ `
  query CompletedProcessStepsByCustomItemTypeIdOld(
    $customItemTypeIdOld: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByCustomItemTypeIdOld(
      customItemTypeIdOld: $customItemTypeIdOld
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByModifiedBy = /* GraphQL */ `
  query CompletedProcessStepsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedProcessStepsByUpdatedAt = /* GraphQL */ `
  query CompletedProcessStepsByUpdatedAt(
    $updatedAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelCompletedProcessStepsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedProcessStepsByUpdatedAt(
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModules = /* GraphQL */ `
  query GetModules($id: ID!) {
    getModules(id: $id) {
      id
      name
      key
      description
      createdAt
      updatedAt
      isDefault
      RoleBaseModuleAccess {
        id
        role
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        access
        deniedFields
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      OrganizationsIntegrations {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      modulesRoleBaseModuleAccessId
      modulesOrganizationsIntegrationsId
      __typename
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleBaseModuleAccess = /* GraphQL */ `
  query GetRoleBaseModuleAccess($id: ID!) {
    getRoleBaseModuleAccess(id: $id) {
      id
      role
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      access
      deniedFields
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoleBaseModuleAccesses = /* GraphQL */ `
  query ListRoleBaseModuleAccesses(
    $filter: ModelRoleBaseModuleAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleBaseModuleAccesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        access
        deniedFields
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemProcesses = /* GraphQL */ `
  query GetItemProcesses($id: ID!) {
    getItemProcesses(id: $id) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      currentPhaseId
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessCompleted
      isDefaultProcess
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listItemProcesses = /* GraphQL */ `
  query ListItemProcesses(
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemProcesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemProcessesByItemsId = /* GraphQL */ `
  query ItemProcessesByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemProcessesByProcessId = /* GraphQL */ `
  query ItemProcessesByProcessId(
    $processId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByProcessId(
      processId: $processId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemProcessesByCurrentPhaseId = /* GraphQL */ `
  query ItemProcessesByCurrentPhaseId(
    $currentPhaseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByCurrentPhaseId(
      currentPhaseId: $currentPhaseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemProcessesByOrganizationID = /* GraphQL */ `
  query ItemProcessesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemProcessesByOrganizationUpdatedAt = /* GraphQL */ `
  query ItemProcessesByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemProcessesByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForneyVaultConfigurations = /* GraphQL */ `
  query GetForneyVaultConfigurations($id: ID!) {
    getForneyVaultConfigurations(id: $id) {
      id
      url
      login
      password
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listForneyVaultConfigurations = /* GraphQL */ `
  query ListForneyVaultConfigurations(
    $filter: ModelForneyVaultConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForneyVaultConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        login
        password
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultConfigurationsByModifiedBy = /* GraphQL */ `
  query ForneyVaultConfigurationsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultConfigurationsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        login
        password
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultConfigurationsByOrganizationID = /* GraphQL */ `
  query ForneyVaultConfigurationsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultConfigurationsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        login
        password
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForneyVaultSetting = /* GraphQL */ `
  query GetForneyVaultSetting($id: ID!) {
    getForneyVaultSetting(id: $id) {
      id
      isEnabled
      userMerticUnits
      projectNumber
      title
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      labNamePropertyId
      LabNameProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      diameterPropertyId
      DiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      lengthPropertyId
      LengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      expectedStrengthPropertyId
      ExpectedStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      strippingStrengthPropertyId
      StrippingStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ageToBreaksString
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      projectsForneyVaultSettingId
      __typename
    }
  }
`;
export const listForneyVaultSettings = /* GraphQL */ `
  query ListForneyVaultSettings(
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForneyVaultSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByOrganizationID = /* GraphQL */ `
  query ForneyVaultSettingsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByItemTypeIdAndId = /* GraphQL */ `
  query ForneyVaultSettingsByItemTypeIdAndId(
    $itemTypeId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByItemTypeIdAndId(
      itemTypeId: $itemTypeId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByLabNamePropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByLabNamePropertyId(
    $labNamePropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByLabNamePropertyId(
      labNamePropertyId: $labNamePropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByDiameterPropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByDiameterPropertyId(
    $diameterPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByDiameterPropertyId(
      diameterPropertyId: $diameterPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByLengthPropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByLengthPropertyId(
    $lengthPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByLengthPropertyId(
      lengthPropertyId: $lengthPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByExpectedStrengthPropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByExpectedStrengthPropertyId(
    $expectedStrengthPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByExpectedStrengthPropertyId(
      expectedStrengthPropertyId: $expectedStrengthPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByStrippingStrengthPropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByStrippingStrengthPropertyId(
    $strippingStrengthPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByStrippingStrengthPropertyId(
      strippingStrengthPropertyId: $strippingStrengthPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByPourDatePropertyId = /* GraphQL */ `
  query ForneyVaultSettingsByPourDatePropertyId(
    $pourDatePropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByPourDatePropertyId(
      pourDatePropertyId: $pourDatePropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultSettingsByModifiedBy = /* GraphQL */ `
  query ForneyVaultSettingsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultSettingsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchForneyVaultSettings = /* GraphQL */ `
  query SearchForneyVaultSettings(
    $filter: SearchableForneyVaultSettingFilterInput
    $sort: [SearchableForneyVaultSettingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableForneyVaultSettingAggregationInput]
  ) {
    searchForneyVaultSettings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        isEnabled
        userMerticUnits
        projectNumber
        title
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        labNamePropertyId
        LabNameProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        diameterPropertyId
        DiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        lengthPropertyId
        LengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        expectedStrengthPropertyId
        ExpectedStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        strippingStrengthPropertyId
        StrippingStrengthProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ageToBreaksString
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        projectsForneyVaultSettingId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getForneyVaultLogs = /* GraphQL */ `
  query GetForneyVaultLogs($id: ID!) {
    getForneyVaultLogs(id: $id) {
      id
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      httpMethodType
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listForneyVaultLogs = /* GraphQL */ `
  query ListForneyVaultLogs(
    $filter: ModelForneyVaultLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForneyVaultLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        httpMethodType
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultLogsByItemsIdAndId = /* GraphQL */ `
  query ForneyVaultLogsByItemsIdAndId(
    $itemsId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultLogsByItemsIdAndId(
      itemsId: $itemsId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        httpMethodType
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultLogsByOrganizationID = /* GraphQL */ `
  query ForneyVaultLogsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultLogsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        httpMethodType
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultLogsByModifiedBy = /* GraphQL */ `
  query ForneyVaultLogsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultLogsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        httpMethodType
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchForneyVaultLogs = /* GraphQL */ `
  query SearchForneyVaultLogs(
    $filter: SearchableForneyVaultLogsFilterInput
    $sort: [SearchableForneyVaultLogsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableForneyVaultLogsAggregationInput]
  ) {
    searchForneyVaultLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        httpMethodType
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getForneyVaultTestResults = /* GraphQL */ `
  query GetForneyVaultTestResults($id: ID!) {
    getForneyVaultTestResults(id: $id) {
      id
      itemsId
      itemSerialNumber
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      maxStress
      maxLoad
      ageToBreak
      diameter
      length
      number
      labName
      labId
      technicianName
      technicianInitials
      actualBreakDayAndTime
      ageAtBreak
      fractureType
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listForneyVaultTestResults = /* GraphQL */ `
  query ListForneyVaultTestResults(
    $filter: ModelForneyVaultTestResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForneyVaultTestResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        itemSerialNumber
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        maxStress
        maxLoad
        ageToBreak
        diameter
        length
        number
        labName
        labId
        technicianName
        technicianInitials
        actualBreakDayAndTime
        ageAtBreak
        fractureType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultTestResultsByItemsIdAndId = /* GraphQL */ `
  query ForneyVaultTestResultsByItemsIdAndId(
    $itemsId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultTestResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultTestResultsByItemsIdAndId(
      itemsId: $itemsId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        itemSerialNumber
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        maxStress
        maxLoad
        ageToBreak
        diameter
        length
        number
        labName
        labId
        technicianName
        technicianInitials
        actualBreakDayAndTime
        ageAtBreak
        fractureType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultTestResultsByOrganizationID = /* GraphQL */ `
  query ForneyVaultTestResultsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultTestResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultTestResultsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        itemSerialNumber
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        maxStress
        maxLoad
        ageToBreak
        diameter
        length
        number
        labName
        labId
        technicianName
        technicianInitials
        actualBreakDayAndTime
        ageAtBreak
        fractureType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forneyVaultTestResultsByModifiedBy = /* GraphQL */ `
  query ForneyVaultTestResultsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForneyVaultTestResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forneyVaultTestResultsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemsId
        itemSerialNumber
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        maxStress
        maxLoad
        ageToBreak
        diameter
        length
        number
        labName
        labId
        technicianName
        technicianInitials
        actualBreakDayAndTime
        ageAtBreak
        fractureType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchForneyVaultTestResults = /* GraphQL */ `
  query SearchForneyVaultTestResults(
    $filter: SearchableForneyVaultTestResultsFilterInput
    $sort: [SearchableForneyVaultTestResultsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableForneyVaultTestResultsAggregationInput]
  ) {
    searchForneyVaultTestResults(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        itemsId
        itemSerialNumber
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        maxStress
        maxLoad
        ageToBreak
        diameter
        length
        number
        labName
        labId
        technicianName
        technicianInitials
        actualBreakDayAndTime
        ageAtBreak
        fractureType
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrganizationsIntegrations = /* GraphQL */ `
  query GetOrganizationsIntegrations($id: ID!) {
    getOrganizationsIntegrations(id: $id) {
      id
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isSubscribed
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listOrganizationsIntegrations = /* GraphQL */ `
  query ListOrganizationsIntegrations(
    $filter: ModelOrganizationsIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsIntegrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationsIntegrationsByOrganizationID = /* GraphQL */ `
  query OrganizationsIntegrationsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationsIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsIntegrationsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationsIntegrationsByModifiedBy = /* GraphQL */ `
  query OrganizationsIntegrationsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationsIntegrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsIntegrationsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHiCamsSettings = /* GraphQL */ `
  query GetHiCamsSettings($id: ID!) {
    getHiCamsSettings(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isEnabled
      restrictedByCustomer
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      producerType
      itemTypeId
      ItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      preStressedItemTypeId
      PreStressedItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      plantIdPropertyId
      PlantIdPropertyId {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialDescProperty
      MaterialDescProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialIdPropertyId
      MaterialIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialTypeIdPropertyId
      MaterialTypeIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      concreteMixPropertyId
      ConcreteMixProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourNumberPropertyId
      PourNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      airContentPropertyId
      AirContentProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      slumpPropertyId
      SlumpProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      spreadPropertyId
      SpreadProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowJRingPropertyId
      FlowJRingProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowLBoxPropertyId
      FlowLBoxProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      bedNumberPropertyId
      BedNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      qtyPropertyId
      QtyProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      contractNumberPropertyId
      ContractNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      standardStrengthIndPropertyId
      StandardStrengthIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      neopreneCapIndPropertyId
      NeopreneCapIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      cylinderDiameterPropertyId
      CylinderDiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      isAvailable
      userHiCamsSettingsId
      customPropertiesHiCamsSettingsId
      itemTypesHiCamsSettingsId
      customersHiCamsSettingsId
      __typename
    }
  }
`;
export const listHiCamsSettings = /* GraphQL */ `
  query ListHiCamsSettings(
    $filter: ModelHiCamsSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiCamsSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isEnabled
        restrictedByCustomer
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        producerType
        itemTypeId
        ItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        preStressedItemTypeId
        PreStressedItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        plantIdPropertyId
        PlantIdPropertyId {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialDescProperty
        MaterialDescProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialIdPropertyId
        MaterialIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialTypeIdPropertyId
        MaterialTypeIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        concreteMixPropertyId
        ConcreteMixProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourNumberPropertyId
        PourNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        airContentPropertyId
        AirContentProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        slumpPropertyId
        SlumpProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        spreadPropertyId
        SpreadProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowJRingPropertyId
        FlowJRingProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowLBoxPropertyId
        FlowLBoxProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        bedNumberPropertyId
        BedNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        qtyPropertyId
        QtyProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        contractNumberPropertyId
        ContractNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        standardStrengthIndPropertyId
        StandardStrengthIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        neopreneCapIndPropertyId
        NeopreneCapIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        cylinderDiameterPropertyId
        CylinderDiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        isAvailable
        userHiCamsSettingsId
        customPropertiesHiCamsSettingsId
        itemTypesHiCamsSettingsId
        customersHiCamsSettingsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsSettingsByOrganizationID = /* GraphQL */ `
  query HiCamsSettingsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsSettingsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isEnabled
        restrictedByCustomer
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        producerType
        itemTypeId
        ItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        preStressedItemTypeId
        PreStressedItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        plantIdPropertyId
        PlantIdPropertyId {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialDescProperty
        MaterialDescProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialIdPropertyId
        MaterialIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialTypeIdPropertyId
        MaterialTypeIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        concreteMixPropertyId
        ConcreteMixProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourNumberPropertyId
        PourNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        airContentPropertyId
        AirContentProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        slumpPropertyId
        SlumpProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        spreadPropertyId
        SpreadProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowJRingPropertyId
        FlowJRingProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowLBoxPropertyId
        FlowLBoxProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        bedNumberPropertyId
        BedNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        qtyPropertyId
        QtyProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        contractNumberPropertyId
        ContractNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        standardStrengthIndPropertyId
        StandardStrengthIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        neopreneCapIndPropertyId
        NeopreneCapIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        cylinderDiameterPropertyId
        CylinderDiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        isAvailable
        userHiCamsSettingsId
        customPropertiesHiCamsSettingsId
        itemTypesHiCamsSettingsId
        customersHiCamsSettingsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsSettingsByModifiedBy = /* GraphQL */ `
  query HiCamsSettingsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsSettingsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isEnabled
        restrictedByCustomer
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        producerType
        itemTypeId
        ItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        preStressedItemTypeId
        PreStressedItemType {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        plantIdPropertyId
        PlantIdPropertyId {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialDescProperty
        MaterialDescProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialIdPropertyId
        MaterialIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        materialTypeIdPropertyId
        MaterialTypeIdProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        concreteMixPropertyId
        ConcreteMixProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourDatePropertyId
        PourDateProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        pourNumberPropertyId
        PourNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        airContentPropertyId
        AirContentProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        slumpPropertyId
        SlumpProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        spreadPropertyId
        SpreadProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowJRingPropertyId
        FlowJRingProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        flowLBoxPropertyId
        FlowLBoxProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        bedNumberPropertyId
        BedNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        qtyPropertyId
        QtyProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        contractNumberPropertyId
        ContractNumberProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        standardStrengthIndPropertyId
        StandardStrengthIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        neopreneCapIndPropertyId
        NeopreneCapIndProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        cylinderDiameterPropertyId
        CylinderDiameterProperty {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        isAvailable
        userHiCamsSettingsId
        customPropertiesHiCamsSettingsId
        itemTypesHiCamsSettingsId
        customersHiCamsSettingsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHiCamsConfigurations = /* GraphQL */ `
  query GetHiCamsConfigurations($id: ID!) {
    getHiCamsConfigurations(id: $id) {
      id
      environment
      apiUrl
      apiType
      apiSegment
      apiSecurityKey
      apiSource
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listHiCamsConfigurations = /* GraphQL */ `
  query ListHiCamsConfigurations(
    $filter: ModelHiCamsConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiCamsConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        environment
        apiUrl
        apiType
        apiSegment
        apiSecurityKey
        apiSource
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsConfigurationsByModifiedBy = /* GraphQL */ `
  query HiCamsConfigurationsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsConfigurationsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        environment
        apiUrl
        apiType
        apiSegment
        apiSecurityKey
        apiSource
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsConfigurationsByOrganizationID = /* GraphQL */ `
  query HiCamsConfigurationsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsConfigurationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsConfigurationsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        environment
        apiUrl
        apiType
        apiSegment
        apiSecurityKey
        apiSource
        isDeleted
        lastTestFetched
        modifiedBy
        ModifiedBy {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHiCamsLogs = /* GraphQL */ `
  query GetHiCamsLogs($id: ID!) {
    getHiCamsLogs(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      productSegment
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isResent
      producerType
      httpMethodType
      isDeleted
      deletedAt
      isAvailable
      __typename
    }
  }
`;
export const listHiCamsLogs = /* GraphQL */ `
  query ListHiCamsLogs(
    $filter: ModelHiCamsLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiCamsLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        productSegment
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isResent
        producerType
        httpMethodType
        isDeleted
        deletedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsLogsByOrganizationID = /* GraphQL */ `
  query HiCamsLogsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsLogsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        productSegment
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isResent
        producerType
        httpMethodType
        isDeleted
        deletedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsLogsByItemsId = /* GraphQL */ `
  query HiCamsLogsByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsLogsByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        productSegment
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isResent
        producerType
        httpMethodType
        isDeleted
        deletedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hiCamsLogsByModifiedBy = /* GraphQL */ `
  query HiCamsLogsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHiCamsLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hiCamsLogsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        productSegment
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isResent
        producerType
        httpMethodType
        isDeleted
        deletedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchHiCamsLogs = /* GraphQL */ `
  query SearchHiCamsLogs(
    $filter: SearchableHiCamsLogsFilterInput
    $sort: [SearchableHiCamsLogsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHiCamsLogsAggregationInput]
  ) {
    searchHiCamsLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        request
        response
        status
        sentOn
        isSent
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        apiEndPoint
        productSegment
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isResent
        producerType
        httpMethodType
        isDeleted
        deletedAt
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getShipments = /* GraphQL */ `
  query GetShipments($id: ID!) {
    getShipments(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      name
      searchNumber
      startWith
      number
      scheduleDate
      shippedDate
      notes
      isShipped
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      maximumWeight
      createdAt
      updatedAt
      deletedAt
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const listShipments = /* GraphQL */ `
  query ListShipments(
    $filter: ModelShipmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByOrganizationID = /* GraphQL */ `
  query ShipmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByProjectId = /* GraphQL */ `
  query ShipmentsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByDefaultProcessId = /* GraphQL */ `
  query ShipmentsByDefaultProcessId(
    $defaultProcessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByDefaultProcessId(
      defaultProcessId: $defaultProcessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentsByModifiedBy = /* GraphQL */ `
  query ShipmentsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchShipments = /* GraphQL */ `
  query SearchShipments(
    $filter: SearchableShipmentsFilterInput
    $sort: [SearchableShipmentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableShipmentsAggregationInput]
  ) {
    searchShipments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getShipmentCustomProperties = /* GraphQL */ `
  query GetShipmentCustomProperties($id: ID!) {
    getShipmentCustomProperties(id: $id) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listShipmentCustomProperties = /* GraphQL */ `
  query ListShipmentCustomProperties(
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipmentCustomProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentCustomPropertiesByCustomPropertyId = /* GraphQL */ `
  query ShipmentCustomPropertiesByCustomPropertyId(
    $customPropertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentCustomPropertiesByCustomPropertyId(
      customPropertyId: $customPropertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentCustomPropertiesByShipmentId = /* GraphQL */ `
  query ShipmentCustomPropertiesByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentCustomPropertiesByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentCustomPropertiesByPropertyDropdownId = /* GraphQL */ `
  query ShipmentCustomPropertiesByPropertyDropdownId(
    $propertyDropdownId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentCustomPropertiesByPropertyDropdownId(
      propertyDropdownId: $propertyDropdownId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentCustomPropertiesByOrganizationID = /* GraphQL */ `
  query ShipmentCustomPropertiesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentCustomPropertiesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const shipmentCustomPropertiesByOrganizationUpdatedAt = /* GraphQL */ `
  query ShipmentCustomPropertiesByOrganizationUpdatedAt(
    $organizationID: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShipmentCustomPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipmentCustomPropertiesByOrganizationUpdatedAt(
      organizationID: $organizationID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyValue
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        requiredProperty
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemTypeInShipment = /* GraphQL */ `
  query GetItemTypeInShipment($id: ID!) {
    getItemTypeInShipment(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      shipmentId
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      quantity
      isDeleted
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      projectsItemTypeInShipmentId
      __typename
    }
  }
`;
export const listItemTypeInShipments = /* GraphQL */ `
  query ListItemTypeInShipments(
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemTypeInShipments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByOrganizationID = /* GraphQL */ `
  query ItemTypeInShipmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByShipmentId = /* GraphQL */ `
  query ItemTypeInShipmentsByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByProjectId = /* GraphQL */ `
  query ItemTypeInShipmentsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByItemTypeId = /* GraphQL */ `
  query ItemTypeInShipmentsByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTypeInShipmentsByModifiedBy = /* GraphQL */ `
  query ItemTypeInShipmentsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTypeInShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTypeInShipmentsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        shipmentId
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        quantity
        isDeleted
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        projectsItemTypeInShipmentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMigrationError = /* GraphQL */ `
  query GetMigrationError($id: ID!) {
    getMigrationError(id: $id) {
      id
      organization
      type
      user
      error
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const listMigrationErrors = /* GraphQL */ `
  query ListMigrationErrors(
    $filter: ModelMigrationErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrationErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organization
        type
        user
        error
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocuments = /* GraphQL */ `
  query GetDocuments($id: ID!) {
    getDocuments(id: $id) {
      id
      parentId
      name
      fileName
      fileType
      isDeleted
      sequence
      type
      extension
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      isFromMobile
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentsByOrganizationID = /* GraphQL */ `
  query DocumentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentsByModifiedBy = /* GraphQL */ `
  query DocumentsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDocuments = /* GraphQL */ `
  query SearchDocuments(
    $filter: SearchableDocumentsFilterInput
    $sort: [SearchableDocumentsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDocumentsAggregationInput]
  ) {
    searchDocuments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDocumentAttachments = /* GraphQL */ `
  query GetDocumentAttachments($id: ID!) {
    getDocumentAttachments(id: $id) {
      id
      documentId
      document {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      associatedAt
      isDeleted
      isDefault
      type
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      version
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentAttachments = /* GraphQL */ `
  query ListDocumentAttachments(
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByDocumentId = /* GraphQL */ `
  query DocumentAttachmentsByDocumentId(
    $documentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByDocumentId(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByOrganizationID = /* GraphQL */ `
  query DocumentAttachmentsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByProjectId = /* GraphQL */ `
  query DocumentAttachmentsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByItemsId = /* GraphQL */ `
  query DocumentAttachmentsByItemsId(
    $itemsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByItemsId(
      itemsId: $itemsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByItemTypeId = /* GraphQL */ `
  query DocumentAttachmentsByItemTypeId(
    $itemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByItemTypeId(
      itemTypeId: $itemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentAttachmentsByModifiedBy = /* GraphQL */ `
  query DocumentAttachmentsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentAttachmentsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        document {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        associatedAt
        isDeleted
        isDefault
        type
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        version
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventoryResult = /* GraphQL */ `
  query GetInventoryResult($id: ID!) {
    getInventoryResult(id: $id) {
      id
      projectId
      projectcode
      projectname
      projectdescription
      rfidcode
      inventorystatus
      inventorynumber
      inventorydate
      bucketlistid
      bucketlistname
      integrationid
      organizationID
      tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInventoryResults = /* GraphQL */ `
  query ListInventoryResults(
    $filter: ModelInventoryResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        projectcode
        projectname
        projectdescription
        rfidcode
        inventorystatus
        inventorynumber
        inventorydate
        bucketlistid
        bucketlistname
        integrationid
        organizationID
        tag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoryResultsByOrganizationID = /* GraphQL */ `
  query InventoryResultsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoryResultsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        projectcode
        projectname
        projectdescription
        rfidcode
        inventorystatus
        inventorynumber
        inventorydate
        bucketlistid
        bucketlistname
        integrationid
        organizationID
        tag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRFIDSyncTime = /* GraphQL */ `
  query GetRFIDSyncTime($id: ID!) {
    getRFIDSyncTime(id: $id) {
      id
      RFIDGoLastSyncTime
      RFIDHubLastSyncTime
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRFIDSyncTimes = /* GraphQL */ `
  query ListRFIDSyncTimes(
    $filter: ModelRFIDSyncTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRFIDSyncTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        RFIDGoLastSyncTime
        RFIDHubLastSyncTime
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rFIDSyncTimesByOrganizationID = /* GraphQL */ `
  query RFIDSyncTimesByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRFIDSyncTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rFIDSyncTimesByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        RFIDGoLastSyncTime
        RFIDHubLastSyncTime
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRfidConfiguration = /* GraphQL */ `
  query GetRfidConfiguration($id: ID!) {
    getRfidConfiguration(id: $id) {
      id
      rfidGoURL
      rfidGoUsername
      rfidGoPassword
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRfidConfigurations = /* GraphQL */ `
  query ListRfidConfigurations(
    $filter: ModelRfidConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRfidConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfidGoURL
        rfidGoUsername
        rfidGoPassword
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRfidBucketList = /* GraphQL */ `
  query GetRfidBucketList($id: ID!) {
    getRfidBucketList(id: $id) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      integrationId
      projectId
      name
      description
      enrollmentRule
      qualifications
      bucketItems
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRfidBucketLists = /* GraphQL */ `
  query ListRfidBucketLists(
    $filter: ModelRfidBucketListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRfidBucketLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        integrationId
        projectId
        name
        description
        enrollmentRule
        qualifications
        bucketItems
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rfidBucketListsByOrganizationID = /* GraphQL */ `
  query RfidBucketListsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRfidBucketListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rfidBucketListsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        integrationId
        projectId
        name
        description
        enrollmentRule
        qualifications
        bucketItems
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchRfidBucketLists = /* GraphQL */ `
  query SearchRfidBucketLists(
    $filter: SearchableRfidBucketListFilterInput
    $sort: [SearchableRfidBucketListSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRfidBucketListAggregationInput]
  ) {
    searchRfidBucketLists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        integrationId
        projectId
        name
        description
        enrollmentRule
        qualifications
        bucketItems
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRfidLogs = /* GraphQL */ `
  query GetRfidLogs($id: ID!) {
    getRfidLogs(id: $id) {
      id
      organizationID
      action
      status
      request
      apiEndPoint
      httpMethodType
      response
      isResent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRfidLogs = /* GraphQL */ `
  query ListRfidLogs(
    $filter: ModelRfidLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRfidLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        action
        status
        request
        apiEndPoint
        httpMethodType
        response
        isResent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rfidLogsByOrganizationID = /* GraphQL */ `
  query RfidLogsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRfidLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rfidLogsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        action
        status
        request
        apiEndPoint
        httpMethodType
        response
        isResent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchRfidLogs = /* GraphQL */ `
  query SearchRfidLogs(
    $filter: SearchableRfidLogsFilterInput
    $sort: [SearchableRfidLogsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRfidLogsAggregationInput]
  ) {
    searchRfidLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        action
        status
        request
        apiEndPoint
        httpMethodType
        response
        isResent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getNotificationGroups = /* GraphQL */ `
  query GetNotificationGroups($id: ID!) {
    getNotificationGroups(id: $id) {
      id
      organizationID
      name
      description
      memberIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      __typename
    }
  }
`;
export const listNotificationGroups = /* GraphQL */ `
  query ListNotificationGroups(
    $filter: ModelNotificationGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationGroupsByOrganizationID = /* GraphQL */ `
  query NotificationGroupsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationGroupsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationGroupsByCreatedBy = /* GraphQL */ `
  query NotificationGroupsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationGroupsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationGroupsByModifiedBy = /* GraphQL */ `
  query NotificationGroupsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationGroupsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchNotificationGroups = /* GraphQL */ `
  query SearchNotificationGroups(
    $filter: SearchableNotificationGroupsFilterInput
    $sort: [SearchableNotificationGroupsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNotificationGroupsAggregationInput]
  ) {
    searchNotificationGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      organizationID
      name
      description
      memberIds
      groupIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      NotificationsUsers {
        items {
          id
          organizationID
          isRead
          isDeleted
          isArchived
          notificationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByOrganizationID = /* GraphQL */ `
  query NotificationsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByCreatedBy = /* GraphQL */ `
  query NotificationsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByModifiedBy = /* GraphQL */ `
  query NotificationsByModifiedBy(
    $modifiedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByModifiedBy(
      modifiedBy: $modifiedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchableNotificationsFilterInput
    $sort: [SearchableNotificationsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNotificationsAggregationInput]
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getNotificationsUsers = /* GraphQL */ `
  query GetNotificationsUsers($id: ID!) {
    getNotificationsUsers(id: $id) {
      id
      organizationID
      isRead
      isDeleted
      isArchived
      notificationId
      Notifications {
        id
        organizationID
        name
        description
        memberIds
        groupIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotificationsUsers = /* GraphQL */ `
  query ListNotificationsUsers(
    $filter: ModelNotificationsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        isRead
        isDeleted
        isArchived
        notificationId
        Notifications {
          id
          organizationID
          name
          description
          memberIds
          groupIds
          createdAt
          updatedAt
          createdBy
          modifiedBy
          __typename
        }
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsUsersByOrganizationID = /* GraphQL */ `
  query NotificationsUsersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsUsersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        isRead
        isDeleted
        isArchived
        notificationId
        Notifications {
          id
          organizationID
          name
          description
          memberIds
          groupIds
          createdAt
          updatedAt
          createdBy
          modifiedBy
          __typename
        }
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsUsersByNotificationId = /* GraphQL */ `
  query NotificationsUsersByNotificationId(
    $notificationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsUsersByNotificationId(
      notificationId: $notificationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        isRead
        isDeleted
        isArchived
        notificationId
        Notifications {
          id
          organizationID
          name
          description
          memberIds
          groupIds
          createdAt
          updatedAt
          createdBy
          modifiedBy
          __typename
        }
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsUsersByUserId = /* GraphQL */ `
  query NotificationsUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationID
        isRead
        isDeleted
        isArchived
        notificationId
        Notifications {
          id
          organizationID
          name
          description
          memberIds
          groupIds
          createdAt
          updatedAt
          createdBy
          modifiedBy
          __typename
        }
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchNotificationsUsers = /* GraphQL */ `
  query SearchNotificationsUsers(
    $filter: SearchableNotificationsUsersFilterInput
    $sort: [SearchableNotificationsUsersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNotificationsUsersAggregationInput]
  ) {
    searchNotificationsUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        isRead
        isDeleted
        isArchived
        notificationId
        Notifications {
          id
          organizationID
          name
          description
          memberIds
          groupIds
          createdAt
          updatedAt
          createdBy
          modifiedBy
          __typename
        }
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getQuicksightDashboardLogs = /* GraphQL */ `
  query GetQuicksightDashboardLogs($id: ID!) {
    getQuicksightDashboardLogs(id: $id) {
      id
      userId
      orgName
      dashboardId
      sheetId
      sheetname
      startTime
      endTime
      duration
      username
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuicksightDashboardLogs = /* GraphQL */ `
  query ListQuicksightDashboardLogs(
    $filter: ModelQuicksightDashboardLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuicksightDashboardLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgName
        dashboardId
        sheetId
        sheetname
        startTime
        endTime
        duration
        username
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const quicksightDashboardLogsByOrganizationID = /* GraphQL */ `
  query QuicksightDashboardLogsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuicksightDashboardLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quicksightDashboardLogsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgName
        dashboardId
        sheetId
        sheetname
        startTime
        endTime
        duration
        username
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSyncingLogs = /* GraphQL */ `
  query GetSyncingLogs($id: ID!) {
    getSyncingLogs(id: $id) {
      id
      organizationID
      createdAt
      updatedAt
      userID
      deviceType
      deviceVersion
      deviceId
      syncCompletionTime
      lastSyncTime
      internetSpeed
      isCompleted
      progressPercentage
      __typename
    }
  }
`;
export const listSyncingLogs = /* GraphQL */ `
  query ListSyncingLogs(
    $filter: ModelSyncingLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSyncingLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        createdAt
        updatedAt
        userID
        deviceType
        deviceVersion
        deviceId
        syncCompletionTime
        lastSyncTime
        internetSpeed
        isCompleted
        progressPercentage
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchSyncingLogs = /* GraphQL */ `
  query SearchSyncingLogs(
    $filter: SearchableSyncingLogsFilterInput
    $sort: [SearchableSyncingLogsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSyncingLogsAggregationInput]
  ) {
    searchSyncingLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationID
        createdAt
        updatedAt
        userID
        deviceType
        deviceVersion
        deviceId
        syncCompletionTime
        lastSyncTime
        internetSpeed
        isCompleted
        progressPercentage
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRfidProjects = /* GraphQL */ `
  query GetRfidProjects($id: ID!) {
    getRfidProjects(id: $id) {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRfidProjects = /* GraphQL */ `
  query ListRfidProjects(
    $filter: ModelRfidProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRfidProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchRfidProjects = /* GraphQL */ `
  query SearchRfidProjects(
    $filter: SearchableRfidProjectsFilterInput
    $sort: [SearchableRfidProjectsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRfidProjectsAggregationInput]
  ) {
    searchRfidProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        organizationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrganizationUsers = /* GraphQL */ `
  query GetOrganizationUsers($id: ID!) {
    getOrganizationUsers(id: $id) {
      id
      userId
      organizationId
      user {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        __typename
      }
      organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationUsers = /* GraphQL */ `
  query ListOrganizationUsers(
    $filter: ModelOrganizationUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organizationId
        user {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationUsersByUserId = /* GraphQL */ `
  query OrganizationUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organizationId
        user {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationUsersByOrganizationId = /* GraphQL */ `
  query OrganizationUsersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationUsersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        organizationId
        user {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
