import React, { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Grid,
  Row,
  Avatar,
  Dropdown,
  Typography,
  Button,
  message,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  EyeOutlined,
  LogoutOutlined,
  SettingOutlined,
  SyncOutlined,
  BellOutlined,
} from "@ant-design/icons";
import "MainLayout/mainlayout.less";
import { Auth } from "aws-amplify";
import {
  getCurrentOrganization,
  getCurrentUser,
  getCurrentIdToken,
  isSuperAdmin,
  setOrgarnizationData,
  getCurrentToken,
  setOrganizationIntegrations,
  signIn,
} from "store/slices/loginSlice";
import { useSelector } from "react-redux";
import SelectOrganization from "Common/Components/Organization/SelectOrganization";
import ChangePassword from "Common/Components/ChangePassword/ChangePassword";
import { CommonError } from "utils/Helper/CommonError";
import {
  getUsersOrganization,
  listOrganization,
  listOrganizationsIntegrations,
} from "utils/Actions";
import { useDispatch } from "react-redux";
import { UserRole } from "utils/Constants";
import { formattedUserRole } from "utils/Helper/formattedUserRole";
import { refidGo } from "utils/RESTApi";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
const { Text } = Typography;

export default function Mainlayout(props) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isaAdmin = useSelector(isSuperAdmin);
  const currentOrganizationData = useSelector(getCurrentOrganization);
  const currentIdToken = useSelector(getCurrentToken);
  const idtoken = useSelector(getCurrentIdToken);

  const [allOrganization, setAllOrganization] = useState([]);
  const [currentOrganization, setCurrentOrganizatio] = useState(
    currentOrganizationData
  );
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const user = useSelector(getCurrentUser);
  const [itemModal, setItemModal] = useState(false);
  const [isPassword, setIspassword] = useState(false);
  const handleSignOut = async () => {
    try {
      await Auth.signOut({ global: false });
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      CommonError(error);
    }
  };
  useEffect(() => {
    fetchMyAPI();
  }, []);
  const fetchRFIDCanadaData = async () => {
    try {
      await refidGo();
    } catch (err) {
      CommonError(err);
    } finally {
      setLoader(false);
    }
  };
  const fetchMyAPI = useCallback(async () => {
    if (isaAdmin) {
      try {
        const response = await listOrganization(currentIdToken);
        setAllOrganization(response?.items);
      } catch (err) {
        CommonError(err);
      } finally {
        // setLoading(false);
      }
    } else {
      try {
        const response = await getUsersOrganization(user?.sub, currentIdToken);
        const organizationData1 = await response?.items?.map(
          (organizationUserRole) => organizationUserRole.Organization
        );
        setAllOrganization(organizationData1);
      } catch (err) {}
    }
  }, [isaAdmin]); //

  const nextToDashBoard = async () => {
    dispatch(setOrgarnizationData(currentOrganization));
    const filter = { organizationID: { eq: currentOrganization?.id } };
    try {
      const orgIntegrations = await listOrganizationsIntegrations(
        filter,
        currentIdToken
      );
      dispatch(setOrganizationIntegrations(orgIntegrations?.items));
      setItemModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      CommonError(err);
    }
  };

  const organizationSelect = async (organizationData) => {
    setCurrentOrganizatio(organizationData);
  };

  const userMenuItems = [
    {
      label: (
        <div>
          {/* <UserOutlined /> */}
          <Text className="bg">
            {user && formattedUserRole(user["custom:role"])}
          </Text>
        </div>
      ),
      key: "user",
      // onClick: () => history.push("/profile"),
    },
    {
      label: (
        <div>
          <SettingOutlined />
          <Text className="ml-5">Setting</Text>
        </div>
      ),
      key: "Profile",
      onClick: () => {
        if (user?.["custom:role"] === UserRole.SUPERADMIN) {
          let signInUserData = {
            token: currentIdToken,
            userData: user,
            organizationData: currentOrganizationData,
            idToken: idtoken,
            isSetting: true,
          };
          dispatch(signIn(null));

          navigate("/adminlog", { state: signInUserData });
        }
      },
    },
    {
      label: (
        <div>
          <EyeOutlined />
          <Text className="ml-5">Change Password</Text>
        </div>
      ),
      key: "ChangePassword",

      onClick: () => setIspassword(true),
    },
    {
      label: (
        <div>
          <LogoutOutlined />
          <Text className="ml-5">Logout</Text>
        </div>
      ),
      key: "Logout",
      onClick: handleSignOut,
    },
  ];
  const userMenu = (
    <Menu className="dropdown-container">
      {userMenuItems?.map((item) => (
        <Menu.Item key={item.key} onClick={item.onClick}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const pageTitle =
    window.location.pathname.replace(/\//g, " ").replace(/-/g, " ") ||
    "Dashboard";
  return (
    <>
      <Header className="main-haeder">
        <Row align="middle" justify="space-between">
          <Row align="middle" justify="center">
            <Button
              type="text"
              onClick={props?.showDrawer}
              className="mobile-menu p-0 fs-16 "
            >
              <MenuUnfoldOutlined />
            </Button>
            <Typography.Title
              level={4}
              className="mb-0 title text-black fw-bolder capital-text"
            >
              {pageTitle}
            </Typography.Title>
          </Row>
          <Row align="middle">
            {/* <Button shape="circle" className="mr-10">
              <BellOutlined />
            </Button> */}

            {currentOrganization?.name == "RFID_CANADA" && (
              <Button
                shape="round"
                className="dark-primary-btn fetch-button-style"
                type="primary"
                // loading={loader}
                onClick={async () => {
                  setLoader(true);
                  fetchRFIDCanadaData();
                }}
              >
                {loader ? <SyncOutlined spin /> : <SyncOutlined />} Fetch RFID
                Data
              </Button>
            )}
            <Row align="middle avatar-wrap">
              {allOrganization?.length > 1 && (
                <>
                  <Button
                    shape="circle"
                    className="headerButton"
                    onClick={() => setItemModal(true)}
                  >
                    <Tooltip title="Switch Organization">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.3329 2.22217H1.77734L1.98155 13.7777H9.3329V2.22217Z"
                            fill="none"
                          />
                          <path
                            d="M14.0135 4.47218L9.33403 2.66663L9.33398 14.2222H14.2229L14.0135 4.47218Z"
                            fill="none"
                          />
                          <path
                            d="M14.2218 14.3223H14.3218V14.2223V4.42405V4.35273L14.2544 4.3295L9.65163 2.74406V1.77783V1.67783H9.55163H1.77734H1.67734V1.77783V14.2223V14.3223H1.77734H7.76213H14.2218ZM10.4834 5.52625V13.6605H9.65163V3.45119L13.6068 4.81099V13.6605H12.7697V10.8596V10.7596H12.6697H12.146H12.046V10.8596V13.6605H11.2072V5.52625V5.42625H11.1072H10.5834H10.4834V5.52625ZM3.47208 10.8618V13.6605H2.40634V2.33965H8.92787V13.6605H7.86213V10.8618V10.7618H7.76213H3.57208H3.47208V10.8618ZM7.87212 4.22387H7.97326L7.97211 4.12273L7.96687 3.66091L7.96575 3.56205H7.86688H3.25608H3.15608V3.66205V4.12387V4.22387H3.25608H7.87212ZM7.87212 5.56622H7.97326L7.97211 5.46508L7.96687 5.00326L7.96575 4.9044H7.86688H3.25608H3.15608V5.0044V5.46622V5.56622H3.25608H7.87212ZM7.87212 6.90857H7.97326L7.97211 6.80743L7.96687 6.34561L7.96575 6.24675H7.86688H3.25608H3.15608V6.34675V6.80857V6.90857H3.25608H7.87212ZM4.19584 13.6605V11.4236H5.30697V13.6605H4.19584ZM6.03073 13.6605V11.4236H7.14361V13.6605H6.03073Z"
                            fill="#000"
                            stroke="#000"
                            strokeWidth="0.2"
                          />
                        </svg>
                      </span>
                    </Tooltip>
                  </Button>
                </>
              )}
              {/* <Button shape="circle" className="mr-10">
                <NotificationOutlined />
              </Button> */}

              {/* <Button shape="circle" className="mr-10">
                <InfoOutlined />
              </Button> */}

              <Dropdown overlay={userMenu} trigger={["click"]}>
                <span className="cursor-pointer fs-16">
                  <Avatar
                    icon={<UserOutlined />}
                    // className={screens.xs ? "" : "mr-10"}
                  />
                  <span className="wrapper">
                    <span className="name  capital-text">
                      {user?.given_name +
                        " " +
                        (user?.family_name && user?.family_name)}
                    </span>
                    <span className="email">
                      {user && formattedUserRole(user["custom:role"])}
                    </span>
                  </span>
                  <DownOutlined />
                </span>
              </Dropdown>
            </Row>
          </Row>
        </Row>
        <></>
      </Header>
      <SelectOrganization
        itemModal={itemModal}
        organizationSelect={(data) => organizationSelect(data)}
        organizationData={allOrganization}
        currentOrganization={currentOrganization}
        nextToDashBoard={() => nextToDashBoard()}
        setItemModal={() => setItemModal()}
      />
      <ChangePassword
        itemModal={isPassword}
        setItemModal={() => setIspassword()}
      />
    </>
  );
}
