import { CommonError } from "utils/Helper/CommonError";
import { fetchFoldersFromS3 } from "utils/Helper/commonMethods";
import { listProjectsSearch } from "utils/RESTApi";

export const fetchData = async (
  organizationId,
  apiName,
  loadingState,
  projectState,
  itemTypeState,
  nextTokenState,
  search
) => {
  loadingState(true);
  try {
    let params = {
      organizationId: organizationId,
      keyword: search,
      limit: 999,
    };

    const response = await apiName(params);

    if (apiName === listProjectsSearch) {
      projectState(response?.data?.data);
    } else {
      itemTypeState(response?.data?.data);
    }

    nextTokenState && nextTokenState(response?.data?.nextToken);
  } catch (err) {
    CommonError(err);
  } finally {
    loadingState(false);
  }
};

// TreeData options generator :: begins
export const createTreeDataStructure = (paths) => {
  const treeData = [];
  const map = new Map();

  paths.forEach((path) => {
    let currentLevel = treeData;
    path.forEach((folder, index) => {
      if (folder === "") return; // Skip empty folder names

      let node = currentLevel.find((item) => item.title === folder);
      if (!node) {
        node = {
          title: folder,
          value: map.has(folder) ? `${folder}_${map.get(folder)}` : folder,
          children: [],
        };
        currentLevel.push(node);
        map.set(folder, (map.get(folder) || 0) + 1);
      }
      currentLevel = node.children;
    });
  });

  // Remove empty children arrays
  const cleanupEmptyChildren = (nodes) => {
    nodes.forEach((node) => {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        cleanupEmptyChildren(node.children);
      }
    });
  };
  cleanupEmptyChildren(treeData);

  return treeData;
};
// TreeData options generator :: ends

export function createAttachmentToDocument(
  itemTypes,
  items,
  projects,
  id,
  attachmentId,
  editDocument,
  organizationID,
  activeKey,
  editData
) {
  if (editDocument) {
    const maxLength = Math.max(
      itemTypes?.length || 0,
      items?.length || 0,
      projects?.length || 0,
      attachmentId?.length || 0
    );
    return Array(maxLength)
      .fill()
      .map((_, index) => {
        return {
          associatedAt:
            index < attachmentId?.length
              ? editData?.filter((item) => attachmentId[index] === item.id)[0]
                  .associatedAt
              : new Date(),
          documentId: id,
          organizationID: organizationID,
          type: activeKey,
          itemTypeId:
            index < itemTypes?.length && itemTypes !== null
              ? itemTypes[index]
              : null,
          itemsId:
            index < items?.length && items !== null ? items[index] : null,
          projectId:
            index < projects?.length && projects !== null
              ? projects[index]
              : null,
          id: index < attachmentId?.length ? attachmentId[index] : null,
          isDefault: true,
        };
      });
  } else if (!editDocument || editData?.length === 0) {
    const maxLength = Math.max(
      itemTypes?.length,
      items?.length || 0,
      projects?.length || 0
    );
    return Array(maxLength)
      .fill()
      .map((_, index) => ({
        associatedAt: new Date(),
        documentId: id,
        organizationID: organizationID,
        type: activeKey,
        itemTypeId: index < itemTypes?.length ? itemTypes[index] : null,
        itemsId: index < items?.length ? items[index] : null,
        projectId: index < projects?.length ? projects[index] : null,
        isDefault: true,
      }));
  }
}

export function createTree(data) {
  const map = {};
  const tree = [];

  // Create a map for quick lookup by id
  data.forEach((item) => {
    map[item.id] = {
      title: item.name,
      value: item.id,
      children: [],
    };
  });

  // Build the tree structure
  data.forEach((item) => {
    if (item.parentId === null) {
      // If no parent, it is a root node
      tree.push(map[item.id]);
    } else {
      // Add to the parent's children
      if (map[item.parentId]) {
        map[item.parentId].children.push(map[item.id]);
      }
    }
  });

  const finalTree = [
    {
      title: "Documents",
      value: "Documents",
      disabled: true,
      children: tree,
    },
  ];

  return finalTree;
}
