import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import "./DataTable.less";
import TableSkeleton from "../Skeleton/TableSkeleton";

const SearchableTableWithSorting = ({
  data,
  columns,
  screens,
  rowSelection,
  hideSelection = true,
  width,
  rowKey,
  hideScroll,
  handleScroll,
  handleChange,
  loading,
  loadData,
  className,
  components,
  onRow = () => {},
}) => {
  // const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  // const handleSearch = (text) => {
  //   const filtered = data.filter((item) =>
  //     Object.values(item).some((value) =>
  //       String(value).toLowerCase().includes(text.toLowerCase())
  //     )
  //   );
  //   setSearchText(text);
  //   setFilteredData(filtered);
  // };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  return (
    <div>
      {loading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
        </>
      ) : (
        <div
          style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto" }}
          onScroll={handleScroll}
        >
          <Table
            onRow={onRow}
            components={components}
            className={
              className ? `${className} dashboard-table` : "dashboard-table"
            }
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            rowKey={rowKey}
            onChange={(val, filter, sorter) => {
              handleChange(val, sorter);
            }}
            // sortDirections={["ascend", "descend"]}
            // scroll={{ x: width ? width : 200 }}
            size={screens.xxl ? "middle" : "small"}
            rowSelection={
              !hideSelection && {
                type: "checkbox",
                ...rowSelection,
              }
            }
            // pagination={{
            //   size: "default",
            //   defaultPageSize: 10,
            //   showSizeChanger: true,
            //   pageSizeOptions: ["10", "20", "30"],
            //   showQuickJumper: true,
            // }}
            locale={{
              emptyText: "No data",
            }}
            showSorterTooltip={{
              target: "sorter-icon",
              title: "Click to Sort",
            }}
          />
        </div>
      )}
      {loadData && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
            marginTop: "-20px",
          }}
        />
      )}
    </div>
  );
};

export default SearchableTableWithSorting;
