import React, { useEffect, useState } from "react";
import { Button, message, Modal, Upload } from "antd";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import SelectDocuments from "./SelectDocuments";
import { createDocuments } from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
import { saveFileToS3 } from "utils/Helper/commonMethods";

// Global file type configurations
const ALLOWED_FILE_TYPES = {
  // Documents
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  TXT: "text/plain",

  // Spreadsheets
  CSV: "text/csv",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",

  // Presentations
  PPT: "application/vnd.ms-powerpoint",
  PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",

  // Images
  JPEG: "image/jpeg",
  PNG: "image/png",

  // CAD
  DWG: "application/acad", // AutoCAD drawing format
};

const FILE_EXTENSIONS = {
  // Documents
  ".pdf": ALLOWED_FILE_TYPES.PDF,
  ".doc": ALLOWED_FILE_TYPES.DOC,
  ".docx": ALLOWED_FILE_TYPES.DOCX,
  ".txt": ALLOWED_FILE_TYPES.TXT,

  // Spreadsheets
  ".csv": ALLOWED_FILE_TYPES.CSV,
  ".xlsx": ALLOWED_FILE_TYPES.XLSX,
  ".xls": ALLOWED_FILE_TYPES.XLS,

  // Presentations
  ".ppt": ALLOWED_FILE_TYPES.PPT,
  ".pptx": ALLOWED_FILE_TYPES.PPTX,

  // Images
  ".jpg": ALLOWED_FILE_TYPES.JPEG,
  ".jpeg": ALLOWED_FILE_TYPES.JPEG,
  ".png": ALLOWED_FILE_TYPES.PNG,

  // CAD
  ".dwg": ALLOWED_FILE_TYPES.DWG,
};
const MAX_FILE_SIZE = 25;

export default function AddNewDocument(props) {
  const {
    setAddNewDoc,
    addNewDoc,
    userOrganizationId,
    currentIdToken,
    folderKey,
    parentKey,
    treeData,
    folderArray,
    handleDocCustom = () => { },
    handleCustomForm = () => { },
    handleAssociateData = () => { },
  } = props;
  const [selectDoc, setSelectDoc] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const isValidFileType = (file) => {
    const extension = "." + file.name.split(".").pop().toLowerCase();
    const mimeType = file.type.toLowerCase();

    // Check if the file type matches either by extension or MIME type
    return (
      Object.values(ALLOWED_FILE_TYPES).includes(mimeType) ||
      Object.keys(FILE_EXTENSIONS).includes(extension)
    );
  };

  const getReadableFileTypes = () => {
    return Object.keys(FILE_EXTENSIONS)
      .map((ext) => ext.toUpperCase())
      .join(", ");
  };

  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
      const validFile = isValidFileType(file);
      const addedFile = fileList.findIndex((item) => item.name === file.name);

      if (addedFile !== -1) {
        message.error("This file is already added!");
        return false;
      }

      if (!isLt10M) {
        message.error(`File must be smaller than ${MAX_FILE_SIZE}MB!`);
        return false;
      }

      if (!validFile) {
        message.error(
          `Only following file types are allowed: ${getReadableFileTypes()}`
        );
        return false;
      }

      setFileList((prevFile) => [...prevFile, file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    if (!selectDoc) {
      setUploadedData([]);
    }
  }, [selectDoc]);

  const uploadFiles = async () => {
    try {
      setLoading(true);
      const S3Key =
        folderKey === null
          ? "Documents"
          : folderKey !== null && parentKey === null
            ? `Documents/${folderKey}`
            : `Documents/${parentKey}/${folderKey}`;

      const totalFiles = fileList.length;
      let successfulUploads = 0;

      const uploadPromises = fileList.map(async (file) => {
        try {
          const data = await saveFileToS3(file, S3Key);
          const input = {
            fileType: file?.type,
            name: file?.name,
            fileName: data?.key,
            organizationID: userOrganizationId,
            parentId: folderKey,
            createdAt: new Date(),
          };

          const result = await createDocuments(input, currentIdToken);
          setUploadedData((prevData) => [...prevData, result]);
          successfulUploads++;
          return result;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          return null;
        }
      });

      await Promise.all(uploadPromises);

      if (successfulUploads > 0) {
        message.success(`${successfulUploads} Document(s) successfully added`);
      }
      if (successfulUploads < totalFiles) {
        message.warning(
          `${totalFiles - successfulUploads} Document(s) failed to upload`
        );
      }

      setAddNewDoc(false);
      setSelectDoc(true);
      setFileList([]);
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Add New Document"
        width={350}
        centered
        className="modal"
        maskClosable={true}
        open={addNewDoc}
        onCancel={() => {
          setAddNewDoc(false);
          setFileList([]);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={uploadFiles}
            loading={loading}
            disabled={fileList.length === 0}
          >
            Save
          </Button>,
        ]}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="text-sm my-10">
            Click or drag file to this area to upload
          </p>
          <p className="text-xs text-gray-500 mb-4">
            Supported files: {getReadableFileTypes()}
          </p>
          <p className="ant-upload-text">
            <Button type="primary" className="dark-primary-btn">
              <SearchOutlined /> Browse
            </Button>
          </p>
        </Upload.Dragger>
      </Modal>

      <SelectDocuments
        uploadedFiles={uploadedData}
        selectDoc={selectDoc}
        setSelectDoc={() => setSelectDoc()}
        userOrganizationId={userOrganizationId}
        currentIdToken={currentIdToken}
        treeData={treeData}
        folderArray={folderArray}
        handleAssociateData={(data) => handleAssociateData(data)}
        handleDocCustom={(data) => {
          handleDocCustom(data);
        }}
        handleCustomForm={(data) => handleCustomForm(data)}
      />
    </>
  );
}
